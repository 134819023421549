<script setup lang="ts">
import { StrapiBlocks } from "vue-strapi-blocks-renderer";
import Button from "primevue/button";
import { useApiStore } from "@/stores/useApiStore";
import WorldMap from "@/components/WorldMap.vue";

const apiStore = useApiStore();
</script>

<template>
  <div class="home-row welcome_text_container">
    <h2>About "AI Policy Observatory"</h2>
    <div class="welcome_text">
      <StrapiBlocks :content="apiStore.data.editorialTexts.Welcome" />

      <div class="welcome_text_image_container">
        <img class="welcome_text_image" src="@/assets/images/logo2.png" />
      </div>
    </div>
  </div>
  <div class="home-row world-map">
    <WorldMap></WorldMap>
  </div>
  <div class="home-row home-teaser-container">
    <div class="home-teaser">
      <h2>Country comparison</h2>
      <p>Comparative insights on AI policies.</p>
      <Button class="ai-teaser-button" size="large">
        <RouterLink to="/compare"> Compare countries</RouterLink></Button
      >
    </div>
    <div class="home-teaser">
      <h2>Source Archive</h2>
      <p>Curated sources on AI regulation and research.</p>
      <Button class="ai-teaser-button" size="large">
        <RouterLink to="/sources">Get sources</RouterLink>
      </Button>
    </div>
    <div class="home-teaser">
      <h2>Our Team</h2>
      <p>Experts providing analysis on AI and law.</p>
      <Button class="ai-teaser-button" size="large">
        <RouterLink to="/team">View team</RouterLink>
      </Button>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/assets/variables.scss";

.welcome_text_container {
  max-width: $home-card-max-width;
  margin: 0 auto;
}

.welcome_text {
  display: flex;
  gap: 16px;
}
.welcome_text p {
  max-width: 90ch;
  flex: 1;
}
.welcome_text_image_container {
  flex: 0;
  padding-left: 16px;
  padding-right: 8px;
  margin-left: auto;
}
.welcome_text_image {
  width: 180px;
  border-radius: 16px;
}
@media (max-width: $phone-breakpoint) {
  .welcome_text_image {
    width: 100px;
  }
  .welcome_text {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }
  .welcome_text_image_container {
    margin-left: 0;
  }
}

.home-teaser-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  max-width: $home-card-max-width;
  text-align: center;
  padding-top: 32px;
  margin: 0 auto;
  padding-bottom: 12px;
}

.home-teaser {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px 16px 16px 16px;
  border: 1px solid $blue2;
  border-radius: 8px;
  height: 100%;
}

@media (min-width: 768px) {
  .home-teaser-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
}

.ai-teaser-button {
  border-radius: 100vw !important;
  color: $text-color !important;
  background-color: transparent !important; //$background-gradient-2 !important;
  border: 1px solid white !important;
  transition: all 0.4s ease !important;

  margin-top: auto;
  align-self: center;
  &:hover:not(:disabled) {
    border: 1px solid white !important;
    color: white !important;
    background-color: $blue1 !important;
  }
  a:hover {
    text-decoration: none !important;
  }
}
.home-row {
  padding-bottom: 24px;
}
</style>
