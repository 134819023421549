<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, watchEffect } from "vue";

const route = useRoute();
const router = useRouter();
const isNavigating = ref(false);
const isMobileMenuOpen = ref(false);

const toggleMobileMenu = () => {
  isMobileMenuOpen.value = !isMobileMenuOpen.value;
};
watchEffect(() => {
  isNavigating.value = true;
  setTimeout(() => {
    isNavigating.value = false; // Finish navigation with a slight delay to avoid flicker
  }, 50);
});

const isLinkActive = (linkPath: string): boolean => {
  if (isNavigating.value) return false;

  return linkPath === route.path;
};
const navigate = (path: string) => {
  isMobileMenuOpen.value = false;
  if (!isLinkActive(path)) {
    router.push(path);
  }
};
</script>

<template>
  <slot />
  <div class="header">
    <div class="header-nav">
      <div class="header-logo-container">
        <a href="/"
          ><img
            class="header-logo-image"
            src="@/assets/images/logo2.png"
            alt="Logo"
        /></a>
      </div>

      <div class="header-title-container">
        <a class="header-title" href="./">AI Policy Observatory</a>
      </div>

      <div
        class="header-navigation-container"
        :class="{
          'header-navigation-links-mobile': isMobileMenuOpen,
        }">
        <div
          class="header-navigation-item"
          @click.prevent="navigate('/')"
          :class="{ 'header-navigation-item-active': isLinkActive('/') }">
          <a href="/">Home</a>
        </div>
        <div
          class="header-navigation-item"
          @click.prevent="navigate('/compare')"
          :class="{
            'header-navigation-item-active': isLinkActive('/compare'),
          }">
          <a href="/compare">Compare</a>
        </div>
        <div
          class="header-navigation-item"
          @click.prevent="navigate('/sources')"
          :class="{
            'header-navigation-item-active': isLinkActive('/sources'),
          }">
          <a href="/sources">Sources</a>
        </div>
        <div
          class="header-navigation-item"
          @click.prevent="navigate('/team')"
          :class="{ 'header-navigation-item-active': isLinkActive('/team') }">
          <a href="/team">Team</a>
        </div>
      </div>
      <div class="header-navigation-container__mobile">
        <i
          @click="toggleMobileMenu()"
          style="margin-top: 4px"
          class="icon-align-justify"></i>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.header {
  height: 70px;
  padding: 0 12px 0 12px;

  border-bottom: 1px solid;
  border-image: linear-gradient(to left, $background-gradient-2, $blue3) 1;
}

.header-nav {
  color: white;
  display: flex;
  align-items: center;
  margin: 0;
  max-width: 1400px;
}
.header-title-container {
  padding-left: 22px;

  border-left: 1px solid $blue3;
}

.header-title {
  color: $blue3;
  @include large-normal;
}
@media (max-width: $phone-breakpoint) {
  .header-title {
    @include medium-normal;
  }
}
.header-title {
  text-decoration: none;
  transition: all ease-in 0.1s;
  &:hover {
    color: white;
  }
}

.header-navigation-container {
  display: flex;
  margin-left: auto;
  gap: 16px;
  padding-right: 24px;
  padding-left: 24px;
}
@media (min-width: $wide-breakpoint) {
  .header-navigation-container {
    text-align: center;
  }
}
.header-navigation-item a {
  @include medium-normal;
  color: $blue2;
  text-decoration: none;
  transition: all ease-in 0.1s;
  &:hover {
    border-bottom: 1px solid white;
    color: white;
  }
}
.header-navigation-item-active a {
  @include medium-normal;
  color: white;
  border-bottom: 1px solid white;
}
.header-logo-container {
  width: 100px;
  margin-top: 6px;
}

.header-logo-image {
  transform: scaleX(-1);
  height: 70px;
  margin-top: 4px;
  transition: filter 0.4s ease-in-out;
}
.header-logo-image:hover {
  filter: brightness(110%);
}
.icon-align-justify {
  display: inline-block;
  width: 24px;
  height: 24px;

  background: url("@/assets/images/icons/format-justify.svg") no-repeat center;
  background-size: contain;
}
.header-navigation-container__mobile {
  display: none;
  margin-left: auto;
}
@media (max-width: $phone-breakpoint) {
  .header-navigation-container__mobile {
    display: block;
  }
  .header-navigation-container {
    display: none;
  }
}
.header-navigation-links-mobile {
  display: flex;
  gap: 16px;
  text-align: center;
  flex-direction: column;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  color: black;
  background-color: $blue1;
  padding: 10px;
  border: 1px solid $blue3;
  margin: 6px;
  box-shadow: $box-shadow;
  z-index: 99;
  user-select: none;
}
</style>
